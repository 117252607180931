import React from "react"
import Layout from "../components/layout.js"
import Meta from "../components/meta.js"
export default function Discord() {
  return (
    <Layout>
      <Meta />
      <meta http-equiv="refresh" content="0;url=https://media.discordapp.net/attachments/1012015665747591268/1171523108230926467/image.png?ex=655cfcea&is=654a87ea&hm=ddf93e8cddc261cbb2536c1698d747a2f1948c069c9dad734c6db275f19289b7&=&width=373&height=497"/>
    </Layout>
  )
}
